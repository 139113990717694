@mixin foundation-flex-classes {
  // Horizontal alignment using justify-content
  @each $hdir, $prop in map-remove($-zf-flex-justify, 'left') {
    .align-#{$hdir} {
      @include flex-align($x: $hdir);
    }
  }
  
  // Horizontal alignment Specifically for Vertical Menu
  @each $hdir, $prop in map-remove($-zf-flex-justify, 'left', 'justify', 'spaced') {
    .align-#{$hdir} {
      &.vertical.menu > li > a { 
        @include flex-align($x: $hdir);
      }
    }
  }

  // Vertical alignment using align-items and align-self
  @each $vdir, $prop in $-zf-flex-align {
    .align-#{$vdir} {
      @include flex-align($y: $vdir);
    }

    .align-self-#{$vdir} {
      @include flex-align-self($y: $vdir);
    }
  }

  // Central alignment of content
  .align-center-middle {
    @include flex-align($x: center, $y: middle);
    align-content: center;
  }

  // Source ordering
  @include -zf-each-breakpoint {
    @for $i from 1 through 6 {
      .#{$-zf-size}-order-#{$i} {
        @include flex-order($i);
      }
    }
  }
}
